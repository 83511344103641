<template>
  <div>
    <vs-row v-if="product.productOption.includes('pay-now')">
      <vs-col vs-w="12" class="flex items-center ml-1">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fil
              l-rule="evenodd"
              clip-rule="evenodd"
              d="M8.71258 0.673082C8.98875 0.493575 9.34473 0.493575 9.6209 0.673082L17.9542 6.08975C18.1907 6.24347 18.3334 6.50639 18.3334 6.78845V12.6218C18.3334 12.9038 18.1907 13.1668 17.9542 13.3205L9.6209 18.7372C9.34473 18.9167 8.98875 18.9167 8.71258 18.7372L0.379248 13.3205C0.142754 13.1668 7.15796e-05 12.9038 7.15796e-05 12.6218V6.78845C7.15796e-05 6.50639 0.142754 6.24347 0.379248 6.08975L8.71258 0.673082ZM1.66674 7.24069V12.1695L9.16674 17.0445L16.6667 12.1695V7.24069L9.16674 2.36569L1.66674 7.24069Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.16674 11.7885C9.62698 11.7885 10.0001 12.1615 10.0001 12.6218V18.0385C10.0001 18.4987 9.62698 18.8718 9.16674 18.8718C8.7065 18.8718 8.33341 18.4987 8.33341 18.0385V12.6218C8.33341 12.1615 8.7065 11.7885 9.16674 11.7885Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.150712 6.31057C0.41464 5.93353 0.934249 5.84183 1.31129 6.10576L9.16674 11.6046L17.0222 6.10576C17.3992 5.84183 17.9188 5.93353 18.1828 6.31057C18.4467 6.68761 18.355 7.20722 17.978 7.47114L9.64462 13.3045C9.35769 13.5053 8.97579 13.5053 8.68885 13.3045L0.35552 7.47114C-0.0215215 7.20722 -0.113217 6.68761 0.150712 6.31057Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.68885 6.10576C8.97579 5.9049 9.35769 5.9049 9.64462 6.10576L17.978 11.9391C18.355 12.203 18.4467 12.7226 18.1828 13.0997C17.9188 13.4767 17.3992 13.5684 17.0222 13.3045L9.16674 7.80566L1.31129 13.3045C0.934249 13.5684 0.41464 13.4767 0.150712 13.0997C-0.113217 12.7226 -0.0215215 12.203 0.35552 11.9391L8.68885 6.10576Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.16674 0.538452C9.62698 0.538452 10.0001 0.911548 10.0001 1.37179V6.78845C10.0001 7.24869 9.62698 7.62178 9.16674 7.62178C8.7065 7.62178 8.33341 7.24869 8.33341 6.78845V1.37179C8.33341 0.911548 8.7065 0.538452 9.16674 0.538452Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
        <h5 class="ml-5 mb-0">Pay Now payment methods</h5>
      </vs-col>
      <hr class="line-hr mb-2 mt-2 w-full ml-1" />
      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt my-8">
          <vs-col
            v-for="(paynowPlan, key) in product.payNowPaymentMethods"
            :key="key"
            class="w-auto"
          >
            <div class="radio-card" v-if="paynowPlan.isChecked">
              <vs-row>
                <vs-col class="flex radio-label justify-between">
                  <p class="radio-label lg:text-xl text-lg">
                    {{ paynowPlan.title }}
                  </p>
                  <S3ImageGenerator
                    :document="paynowPlan.icon"
                    :custom-class="'card-image-sm'"
                    :is-base64="false"
                  />
                </vs-col>
              </vs-row>

              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center"
                      ><p class="text-xs">Merchant service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(paynowPlan.msf)) }} %</h6></span
                    >
                    <span :class="getChanges(key, 'surcharge') ? 'flex items-center changed' : 'flex items-center'"
                      ><p class="text-xs">Surcharge to customer:</p>
                      <h6 class="ml-2 text-xs">
                        {{ addZeroes(parseFloat(paynowPlan.surcharge)) }} %
                      </h6></span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>

    <vs-row v-if="product.productOption.includes('pay-later')">
      <vs-col vs-w="12" class="flex items-center ml-1">
        <div class="flex justify-center items-center" style="max-width: 20px">
          <svg
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fil
              l-rule="evenodd"
              clip-rule="evenodd"
              d="M8.71258 0.673082C8.98875 0.493575 9.34473 0.493575 9.6209 0.673082L17.9542 6.08975C18.1907 6.24347 18.3334 6.50639 18.3334 6.78845V12.6218C18.3334 12.9038 18.1907 13.1668 17.9542 13.3205L9.6209 18.7372C9.34473 18.9167 8.98875 18.9167 8.71258 18.7372L0.379248 13.3205C0.142754 13.1668 7.15796e-05 12.9038 7.15796e-05 12.6218V6.78845C7.15796e-05 6.50639 0.142754 6.24347 0.379248 6.08975L8.71258 0.673082ZM1.66674 7.24069V12.1695L9.16674 17.0445L16.6667 12.1695V7.24069L9.16674 2.36569L1.66674 7.24069Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.16674 11.7885C9.62698 11.7885 10.0001 12.1615 10.0001 12.6218V18.0385C10.0001 18.4987 9.62698 18.8718 9.16674 18.8718C8.7065 18.8718 8.33341 18.4987 8.33341 18.0385V12.6218C8.33341 12.1615 8.7065 11.7885 9.16674 11.7885Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.150712 6.31057C0.41464 5.93353 0.934249 5.84183 1.31129 6.10576L9.16674 11.6046L17.0222 6.10576C17.3992 5.84183 17.9188 5.93353 18.1828 6.31057C18.4467 6.68761 18.355 7.20722 17.978 7.47114L9.64462 13.3045C9.35769 13.5053 8.97579 13.5053 8.68885 13.3045L0.35552 7.47114C-0.0215215 7.20722 -0.113217 6.68761 0.150712 6.31057Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.68885 6.10576C8.97579 5.9049 9.35769 5.9049 9.64462 6.10576L17.978 11.9391C18.355 12.203 18.4467 12.7226 18.1828 13.0997C17.9188 13.4767 17.3992 13.5684 17.0222 13.3045L9.16674 7.80566L1.31129 13.3045C0.934249 13.5684 0.41464 13.4767 0.150712 13.0997C-0.113217 12.7226 -0.0215215 12.203 0.35552 11.9391L8.68885 6.10576Z"
              fill="#BDBDBD"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.16674 0.538452C9.62698 0.538452 10.0001 0.911548 10.0001 1.37179V6.78845C10.0001 7.24869 9.62698 7.62178 9.16674 7.62178C8.7065 7.62178 8.33341 7.24869 8.33341 6.78845V1.37179C8.33341 0.911548 8.7065 0.538452 9.16674 0.538452Z"
              fill="#BDBDBD"
            />
          </svg>
        </div>
        <h5 class="ml-5 mb-0">Pay Later products</h5>
      </vs-col>
      <hr class="line-hr mb-8 mt-2 w-full ml-1" />
      <!--      <vs-col class="w-full" vs-w="12">
                <span v-if="showEditButton">
                    <vs-icon @click="goToEdit()" icon="edit" size="small" class="float-right"></vs-icon>
                </span>
      </vs-col>-->
      <vs-col vs-w="12" class="onboarding-form">
        <vs-row class="paymentOpt">
          <vs-col
            v-for="(payLaterPlan, key) in product.payLaterProducts"
            :key="key"
            class="w-auto"
          >
            <div class="radio-card" v-if="payLaterPlan.isChecked">
              <vs-row>
                <vs-col
                  vs-w="10"
                  vs-sm="10"
                  vs-lg="10"
                  vs-xs="10"
                  class="radio-label"
                >
                  {{ payLaterPlan.productName }}
                </vs-col>
              </vs-row>
              <vs-row>
                <vs-col vs-w="12" vs-sm="12" vs-lg="12" vs-xs="12">
                  <div>
                    <span class="flex items-center"
                      ><p class="text-xs">Service fee:</p>
                      <h6 class="ml-2 text-xs">{{ addZeroes(parseFloat(payLaterPlan.msf)) }} %</h6></span
                    >
                    <span class="flex items-center" v-if="payLaterPlan.feeModel == 'MSF_INCLUDED'"
                      ><p class="text-xs"></p>(payable by Merchant)</span
                    >
                    <span class="flex items-center" v-else
                      ><p class="text-xs">(payable by Customer)</p></span
                    >
                  </div>
                </vs-col>
              </vs-row>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
import S3ImageGenerator from "../../../components/S3ImageGenerator";

export default {
  name: "ProductSelection",
  components: {
    S3ImageGenerator
  },
  props: {
    product: { type: Object },
    userId: { type: String },
    showEditButton: { type: Boolean },
    status: {type: String},
    resubmitChanges: {type: Array},
  },
  data() {
    return {
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
    };
  },
  methods: {
    /*goToEdit(){
            this.$router.push({name:"application",params:{userId:this.userId,card:"productSelection"}})
        }*/
    addZeroes(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        // maximumFractionDigits: 2,
      });
    },
    getChanges(index, flag){
      if(this.resubmitChanges.length > 0 && this.status == 'Resubmitted'){
        let changedlabel = `payNowPaymentMethods[${index}]${flag}`
        let objData = this.resubmitChanges.findIndex(
          (obj) => obj.label === changedlabel
        );
        if(objData >= 0){
          return true;
        }
      }
      return false;
    }
  },
};
</script>